import React, { useEffect, useState } from 'react'
import Dropzone from '../components/Dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { getImages, deleteImage, showMoreImages, getInterior } from '../redux/slices/imageSlice'
import { useParams } from 'react-router-dom'

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

const InteriorImages = (props) => {

  const [category_id, setCategoryId] = useState(0)
  const [editFlag, setEditFlag] = useState(false)
  const [page, setPage] = useState(1)
  const [currentImage, setCurrentImage] = useState({img: '', i: 0})
  const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3, 1920: 4 };

  const dispatch = useDispatch()

  const category = useSelector(state => state.category.category)

  const images = useSelector(state => state.interiorImages.images)

  const pages = useSelector(state => state.interiorImages.pages)

  const auth = !!useSelector(state => state.auth.data)

  const urlParams = useParams()

  useEffect(() => {
    let id = urlParams ? urlParams.id : 0
    setCategoryId(id)
    dispatch(getInterior(id))
    dispatch(getImages(id))
  }, [editFlag, urlParams])

  const changeFlag = () => {
    setEditFlag(!editFlag)
  }

  const removeImage = async(url, id) => {
    await dispatch(deleteImage({url: url, id: id}))
    changeFlag()
  }

  const showMore = () => {
    let new_page = page + 1
    setPage(new_page)
    let params = {
      category_id : category_id,
      page : new_page
    }
    dispatch(showMoreImages(params))
  }

  const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: true,
    close: true,
    counter: true,
    bgOpacity: 0.2,
    padding: { top: 20, bottom: 20, left: 10, right: 10 },
  }
  
  

  return (
    <div className="main-container">
      <div className="interior-photos-container">
      {images ? 
          <Gallery options={options}>
              <div className="gallery-container">
            {images.map((image, i) => (
              <Item
              key={image.link}
              original={`${process.env.REACT_APP_BASEURL}${image.link}`}
              thumbnail={`${process.env.REACT_APP_BASEURL}${image.link}`}
              width={2500}
              height={2500}
            >
              {({ ref, open }) => (
              <div className="image-container">
                { auth ?
                  <div className="edit-container">
                    <button type="button" className="btn-mate" onClick={() => removeImage(image.link, image.image_id)}>
                      <svg className="svg-remove-card">
                        <use xlinkHref="#svg-trash"></use>
                      </svg>
                    </button>
                  </div>
                  : null
                }
                <div className="inner-container">
                  <img ref={ref} onClick={open} src={`${process.env.REACT_APP_BASEURL}${image.link}`} />
                </div>
              </div>                
              )}
            </Item>
            ))}

            </div>
        </Gallery>
          :
          null
          }

      </div>
        { pages > page ? 
        <div className="show-more-block"><button type="button" onClick={() => showMore()}>Показати бiльше</button></div>
        : null
        }
        { auth ? 
        <div className="add-image-container">
          <div className="page-title">Додати фото</div>
          <div className="dropzone-container">
            <Dropzone editFlag={editFlag} setEditFlag={setEditFlag} category_id={category_id} category={category}/>
          </div>
        </div>
          :
          null}

    </div>
  )
}

export default InteriorImages