import './scss/app.scss';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import InteriorColors from './pages/InteriorColors';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import { useDispatch } from 'react-redux';
import { fetchAuthMe } from './redux/slices/authSlice';
import React from 'react';
import InteriorImages from './pages/InteriorImages';
import InstallPage from './pages/InstallPage';
import AdminPage from './pages/AdminPage';


import Category from './pages/Category';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer';

function App() {

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchAuthMe())
  }, [])

  return (
    <div className="app-wrapper">
      <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="description"
        content="Porsche центр Днiпро. Галерея інтер'єрів."
      />
      <link rel="apple-touch-icon" href={`${process.env.REACT_APP_BASEURL}/apple-touch-icon.png`} />
      <link rel="manifest" href={`${process.env.REACT_APP_BASEURL}/manifest.json`} />
      <link rel="icon" href={`${process.env.REACT_APP_BASEURL}/favicon.ico`} />
      <meta property="og:image" content={`${process.env.REACT_APP_BASEURL}/logo512.png`} />
      <title>Галерея опцій Porsche</title>
      <meta property="og:title" content="Галерея опцій Porsche" />
      <meta property="og:site_name" content="Галерея Porsche" />
      <meta property="og:description" content="Porsche центр Днiпро. Галерея інтер'єрів." />
      </Helmet>
      <Header />
      <div className="router-content">
        <Routes>
          <Route path="/" element={<Category />}></Route>
          <Route path="/category/:id" element={<Category />}></Route>
          <Route path="/model/:id" element={<InteriorColors />}></Route>
          <Route path="/interior/:id" element={<InteriorImages />}></Route>
          <Route path="/register" element={<RegisterPage />}></Route>
          <Route path="/settings" element={<AdminPage />}></Route>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/install" element={<InstallPage />}></Route>
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
