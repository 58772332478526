import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../axios'





const initialState = {
  data: null,
  status: 'loading',
  categories: [],
  category: []
}


export const addCategory = createAsyncThunk("category/addCategory", async (params) => {
  const { data } = await axios.post("category", params)

  return data
})

export const updateCategory = createAsyncThunk("category/updateCategory", async (params) => {

  const { data } = await axios.patch(`category`, params)

  return data
})

export const deleteCategory = createAsyncThunk("category/deleteCategory", async (id) => {

  const { data } = await axios.delete(`category/${id}`)

  return data
})

export const getCategory = createAsyncThunk("category/getCategory", async (id) => {

  const  { data }  = await axios.get(id ? `category/${id}` : 'category')

  return data
})

const categorySlice = createSlice({
  name: 'carCategory',
  initialState,
  extraReducers: (builder) => {
    builder
    .addCase(addCategory.pending, (state) => {
      state.status = "loading";
      state.data = null;
    })
    .addCase(addCategory.fulfilled, (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    })
    .addCase(addCategory.rejected, (state) => {
      state.status = "error";
      state.data = null;
    })
    .addCase(updateCategory.pending, (state) => {
      state.status = "loading";
      state.data = null;
    })
    .addCase(updateCategory.fulfilled, (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    })
    .addCase(updateCategory.rejected, (state) => {
      state.status = "error";
      state.data = null;
    })
    .addCase(deleteCategory.pending, (state) => {
      state.status = "loading";
      state.data = null;
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.status = "loaded";
      state.data = action.payload;
    })
    .addCase(deleteCategory.rejected, (state) => {
      state.status = "error";
      state.data = null;
    })
    .addCase(getCategory.pending, (state) => {
      state.status = "loading";
      state.categories = [];
    })
    .addCase(getCategory.fulfilled, (state, action) => {
      state.status = "loaded";
      state.categories = action.payload.categories;
      state.category = action.payload.category;
    })
    .addCase(getCategory.rejected, (state) => {
      state.status = "error";
      state.categories = [];
    })
  }
})

export default categorySlice.reducer