import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import PopupAddCategory from '../components/PopupAddCategory';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory, getCategory } from '../redux/slices/categorySlice';
import InteriorImages from './InteriorImages';
import { Helmet } from 'react-helmet';

const Category = () => {

  const dispatch = useDispatch()
  const [popupState, setPopupState] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [parent_id, setParent] = useState(false)
  const [category_id, setCategoryId] = useState(0)
  const [params, setParams] = useState({name: '', image: '', sort: '', category_id: '', parent_id: '', parent_name: ''})
  const categories = useSelector(selector => selector.category.categories)
  const categoryInfo = useSelector(selector => selector.category.category)
  const auth = !!useSelector(state => state.auth.data)
  const sortedCategories = [ ...categories ].sort((a, b) => a.sort - b.sort)
  const urlParams = useParams();

  useEffect(() => {
    let id = urlParams ? urlParams.id : 0
    setParent(id)
    dispatch(getCategory(id))
  }, [editFlag, urlParams])

  const handleParams = (event) => {
    let data = { ...params }
    data[event.target.name] = event.target.value
    setParams(data)
  }

  const handleImage = (href) => {
    let data = { ...params }
    data['image'] = href
    setParams(data)
  }

  const popupToggle = (category) => {
    setParams({name: '', image: '', sort: '', category_id: 0, parent_id: categoryInfo ? categoryInfo.category_id : 0, parent_name: categoryInfo ? categoryInfo.name : 0})
    if(category){
      setParams({name: category.name, image: category.image, sort: category.sort, category_id: category.category_id, parent_id: category.parent_id ? category.parent_id : parent_id, parent_name: categoryInfo.name})
    }
    setPopupState(!popupState)
  }

  const changeFlag = () => {
    setEditFlag(!editFlag)
  }

  const removeCategory = async(category_id) => {
    const deleted = await dispatch(deleteCategory(category_id))
    changeFlag()
  }

  return (
    <div className="main-container">
      {categoryInfo.category_id ? 
            <Helmet> 
            <title>{ categoryInfo.name }</title>
            <meta name="description"
            content={`Porsche центр Днiпро. Галерея інтер'єрів. (${ categoryInfo.name })`}/>
            <meta property="og:type" content="article" />
            <meta property="og:title" content={ categoryInfo.name } />
            <meta property="og:description" content={`Галерея інтер'єрів, кольорів та інших опцій Porsche. (${ categoryInfo.name })`} />
            <meta property="og:image" content={`${process.env.REACT_APP_BASEURL}${ categoryInfo.image }`} />
            </Helmet>
            : null}
      <PopupAddCategory popupToggle={popupToggle} popupState={popupState} changeFlag={changeFlag} handleParams={handleParams} handleImage={handleImage} params={params}/>
      <div className="page-title">{ categoryInfo.name }</div>
      <div className="category-colors-container">
        {categories.length ? sortedCategories.map(category => 
          <div className="category-color-card" key={category.category_id}>
            <div className={`category-color-card-inner ${!categoryInfo.category_id ? 'main-category' : ''}`}>
              <div className="category-color-image">
              { auth ? 
                <div className="edit-container">
                    <button type="button" onClick={() => popupToggle(category)}>
                      <svg className="svg-edit-card">
                        <use xlinkHref="#svg-pencyl"></use>
                      </svg>
                    </button>
                    <button type="button" onClick={() => removeCategory(category.category_id)}>
                      <svg className="svg-remove-card">
                        <use xlinkHref="#svg-trash"></use>
                      </svg>
                    </button>
                  </div>
              : null  
              }
                <Link to={`/category/${category.category_id}`}>
                  <img src={`${process.env.REACT_APP_BASEURL}${ category.image }`} alt={category.name} />
                </Link>
              </div> 
              <div className="category-color-name"><Link to={`/category/${category.category_id}`}>{category.name}</Link></div>
            </div>
          </div>
          ) : ''}

        { auth ? 
        <div className="category-color-card">
          <div className="add-color-card-inner" onClick={() => popupToggle()}>
            <svg className="svg-plus">
              <use xlinkHref="#svg-plus"></use>
            </svg>
            <div className="category-color-name">Додати категорiю</div>
          </div>
        </div>
        :
        null }
      </div>


          <InteriorImages />

    </div>
  )
}

export default Category